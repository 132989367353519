<template>
  <div class="wrap-table">
    <el-form :model="formData" label-position="left">
      <el-row style="width: 100%" :gutter="20">
        <el-col
          :sm="24"
          :md="12"
          v-for="(item, index) in formList"
          :key="index"
        >
          <el-form-item :label="item.label">
            <el-input
              v-model="item.Id"
              placeholder="请输入查询字段 ..."
            ></el-input>
            <!-- <el-select v-model="formInline.region" placeholder="Activity zone">
              <el-option label="Zone one" value="shanghai"></el-option>
              <el-option label="Zone two" value="beijing"></el-option>
            </el-select> -->
          </el-form-item>
        </el-col>

        <el-form-item class="wrap-btn" style="margin: 0 auto">
          <el-button size="mini" type="primary" @click="onSubmit"
            >查询</el-button
          >
          <el-button size="mini" type="info">重置</el-button>
          <el-button size="mini" type="success">导出</el-button>
        </el-form-item>
      </el-row>
    </el-form>
    <PublicTable  ref="publicTable"></PublicTable>
  </div>
</template>

<script>
import PublicTable from '../../components/table/PublicTable.vue';
import LoadData from "@/static/GetFileds/Project.json";
export default {
  components: { PublicTable },
  data() {
    return {
      formData: {},
      formInline: {
        user: "",
        region: "",
      },
      formList: [
        { label: "项目编号", Id: "" },
        { label: "测绘类型", Id: "" },
        { label: "项目名称", Id: "" },
        { label: "地块编号", Id: "" },
        { label: "用地单位", Id: "" },
        { label: "土地坐落", Id: "" },
        { label: "测绘单位", Id: "" },
        { label: "项目别名", Id: "" },
      ],
      inputValue: "", //搜索的内容
      url: "", //传给子组件的url
    };
  },
  methods: {
    
    onSubmit() {
      console.log("submit!",this.formList);
    },
    //搜索
    handleQuery() {
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
    //重置
    handleReset() {
      this.inputValue = "";
      this.$refs.publicTable.getDataList(this.url, this.inputValue);
    },
  },
  mounted() {
    this.url = "/api/user/list";
    this.$refs.publicTable.getLoadData(LoadData, this.url, false);
   
  },
};
</script>
<style scoped>
.wrap-table {
  padding-top: 20px;
  background-color: #ffffff;
  /* border-radius: 7px 7px 0px 0px; */
  /* margin: 15px 0px 15px 0px; */
  overflow: hidden;
}
.wrap-table :deep( .el-form--label-left .el-form-item__label) {
  /* display: inline-block;
    max-width: 100%;
    margin-bottom: 5px; */
  font-weight: 700;
  color: #000000;
  padding: 0 15px 0 15px;
}
/* .wrap-table :deep(.el-form){
  padding: 0px 60px 0px 60px;

} */
</style>